import { reactive } from 'vue'
import { formatTime } from '@/utils/time'
export const headers = reactive([
  {
    title: 'Date',
    htmlDomFunc: ({ createTime }: any) => {
      return formatTime(createTime)
    }
  },
  {
    title: 'Reference',
    key: 'receiptNo'
  },
  {
    title: 'Price',
    key: 'amount'
  },
  {
    title: 'Action'
  }
])
