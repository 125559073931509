import { reactive } from 'vue'
import { jurisdictionList } from '@/utils/constData'
import { formatTime } from '@/utils/time'
export const headers = reactive([
  {
    title: 'Type',
    htmlDomFunc: ({ entityName, enName, dataType, reportType }: any) => {
      if (!entityName && !enName) {
        return '<div class="value">No records found</div>'
      }
      const dataMap = dataType === 1 ? 'Deep' : dataType === 2 ? 'Pro' : ''
      const reportMap = reportType === 1 ? 'Excel' : reportType === 2 ? 'Pdf' : ''
      return `
      <div class="value">
        <div>Company Check Report (${dataMap}) - ${reportMap}</div>
        <div>${entityName || ''}</div>
        <div>${enName || ''}</div>
      </div>`
    }
  },
  {
    title: 'Jurisdiction',
    width: 140,
    htmlDomFunc: ({ countryCode }: any) => {
      const countryInfo = jurisdictionList.find(item => item.code === countryCode)
      if (!countryInfo?.code || !countryCode) return '<div class="value">No records found</div>'
      return `
        <div class="value">
          <img src="${countryInfo.src}" style="width: 40px;"/>
          <div>${countryInfo.infoKey}</div>
        </div>`
    }
  },
  {
    title: 'Date',
    width: 140,
    htmlDomFunc: ({ createTime }: any) => {
      return formatTime(createTime)
    }
  },
  {
    title: 'Action',
    width: 140
  }
])
